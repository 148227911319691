<route lang="yaml">
meta:
    layout: 'default-layout'
    requires_auth: false
    hide_for_logged_in: false
</route>

<script setup
        lang="ts">
import { defineAsyncComponent } from 'vue';
import { useHead }              from '@reshepe/hooks';

const View = defineAsyncComponent(() => import('@views/index.vue'));

useHead({
    title: 'home',
});
</script>

<template>
    <View />
</template>
